import { Button, buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";

export const meta: MetaFunction = () => {
  return [{ title: "Iniciar sesión | Miura Seller" }];
};

export default function Login() {
  return (
    <>
      <div className="container relative grid min-h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <Link
          to="https://www.miura.cl"
          className={cn(
            buttonVariants({ variant: "ghost" }),
            "absolute right-4 top-4 md:right-8 md:top-8"
          )}
        >
          Ir a Miura
        </Link>
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
          <div className="absolute inset-0 bg-zinc-900" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            Miura Seller
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">&ldquo;Hakuna Matata.&rdquo;</p>
              <footer className="text-sm">Timón y pumba</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Iniciar sesión
              </h1>
            </div>
            <UserAuthForm />
          </div>
        </div>
      </div>
    </>
  );
}

export function UserAuthForm() {
  return (
    <div className="grid gap-6">
      <form method="post" action="/api/login/google">
        <Button variant="outline" type="submit" className="w-full">
          <IconLogosGoogle className="mr-2 h-4 w-4" />
          Google
        </Button>
      </form>
    </div>
  );
}
